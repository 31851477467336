/* eslint-disable prettier/prettier */
/* eslint-disable react/no-children-prop */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable sonarjs/no-redundant-boolean */
/* eslint-disable no-debugger */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { Card, CardContent, Grid, RACButton, RACModalCard, RACSelect, Typography, CircularProgress, List, ListItem } from '@rentacenter/racstrap';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getTransferReceiveGridDetails, updateTransferReceiveDetails, getCurrentUser,getInventoryStatusReasons, getCoworkerDetails, getprofileaccess, updateExternalTransfers } from '../../api/user';
import { ReactComponent as WrongIcon } from '../../assets/images/no-records-found.svg';
import { ReactComponent as TickIcon } from '../../assets/images/success-icon.svg';
import { ReactComponent as NoRecord } from '../../assets/images/No-records.svg';

import DynamicGridComponent from '../Shared/DynamicGrid';
import TwoFactorComponent from '../Shared/secondAuthen';
import { AcceptTransferReceive, API_ERROR_MESSAGE, rejectReasons, sortDirection } from '../../constants/constants';
import { validateObject } from '../../constants/validateObject'
import PrintTag from './PrintTag';
import { globalStyles } from '../componentstyles/globalstyles';
import { transferReceiveStyles } from '../componentstyles/tranferReceiveStyles';
import { ContainerContext } from '../../app/App';
import { CustomPropInterface } from '../../index';
import { validateData } from '../../constants/CommonMethods';
import { HtAcceptRejectReq } from '../../types/types';

export default function InventoryTransferReceive() {

  const history = useHistory();
  const classes = globalStyles();
  const transferStyles = transferReceiveStyles()
  const [transferReceiveData, setTransferReceiveData] = useState<any[]>([]);
  const [enablePopup, setEnablePopup] = useState(false);
  const [popupType, setPopupType] = useState("")
  const [disableButtons, setDisableButtons] = useState(true);
  const [printdata, setPrintData] = useState<any>();
  const [gridColumns, setGridColumns1] = useState<any>(AcceptTransferReceive);
  const [order, setOrder] = useState<string>(sortDirection.ASC);
  const [orderBy, setOrderBy] = useState<string>('transferringStoreNumber');
  const [dataToPrint, setDataToPrint] = useState<any>([]);
  const [store, setStore] = useState<any>([]);
  const [storeValue, setStoreValue] = useState<any>();
  const [dropDownLoaded, setDropDownLoaded] = useState<boolean>(false);
  const [gridTemp, setGridTemp] = useState<any>();
  const [reasonValue, setReasonValue] = useState<any>('Select');
  const [secondLvlAuth, setsecondLvlAuth] = useState(false);
  const [inventoryNumbers, setInventoryNumbers] = useState<any>([])
  const [externalInventoryNumbers, setExternalInventoryNumbers] = useState<Map<string,string>>(new Map<string,string>());  
  const [loader, setloader] = useState({ pageLoader: false, btnLoader: false });
  const [errMsg, seterrMsg] = useState<any>({ wentWrong: false, noRecord: false });
  const [currentCWRole, setcurrentCWRole] = useState('');
  const currentStoreNumber = window.sessionStorage.getItem('storeNumber')
  const containerData = useContext(ContainerContext) as CustomPropInterface;
  const [profileDetails, setprofileDetails] = useState<any>();
  const [employeeId, setemployeeId] = useState<any>();
  const [rejectReasons, setrejectReasons] = useState<any>([]);
  const [rejectLoader, setrejectLoader] = useState(false);
  const [masterLoader,setmasterLoader] = useState(false);
  let [acceptTransferBtnDisable, setAcceptTransferBtnDisable] = useState<boolean>(false)
  useEffect(() => {
    if (containerData !== undefined) {
      setcurrentCWRole(containerData?.GetRole())
      setemployeeId(containerData?.GetEmployeeId())
    }
    else {
      getCoWorkerRole();
    }

    const getprofileaccessDetails = async () => {
      const payload: any = {
        module: 'Reject Transferred Inventory',
        storeNumber: currentStoreNumber,
        currentRole: containerData?.GetRole()
      }
      const result = await getprofileaccess(payload);
      validateData(result) ?
        setprofileDetails(result) :
        setprofileDetails(undefined);
    }

    if (currentStoreNumber && (typeof currentStoreNumber == 'number' ||
      (typeof currentStoreNumber == "string" && currentStoreNumber.trim() != '' &&
        !isNaN(Number(currentStoreNumber)) && Number.isInteger(Number(currentStoreNumber))))) {
      transferReceiveGridDetailsApiCall();
      getprofileaccessDetails();
    } else {
      seterrMsg({ ...errMsg, wentWrong: true });
    }
  }, []);


  const transferReceiveGridDetailsApiCall = async () => {
    setInventoryNumbers([]);
    setExternalInventoryNumbers(new Map<string, string>());
    setsecondLvlAuth(false);
    setStoreValue('0');
    console.log('i90sdgrgko', currentStoreNumber)

    setloader({ ...loader, pageLoader: true });
    const respData: any = await getTransferReceiveGridDetails(currentStoreNumber);
    setloader({ ...loader, pageLoader: false });

    console.log("uh", respData.data);

    
    if (validateObject(respData) && respData.status !== 200) {
      seterrMsg({ ...errMsg, wentWrong: true });
    }

    if (validateObject(respData) && respData.status == 200) {
      if (validateObject(respData.data) && validateObject(respData.data.inventoryTransferList) && respData.data.inventoryTransferList.length > 0) {
        // setGridColumns1(AcceptTransferReceive);
        // setOrderBy('transferringStoreNumber');
        gridColumns.map((value: any) => {

          if (value.columnId == 'transferringStoreNumber') {
            value.sortOrder = sortDirection.ASC;
            value.IsSorted = true;
          }
          else {
            value.IsSorted = false;
          }
        })
        setTransferReceiveData(respData.data.inventoryTransferList);
        setGridTemp(respData.data.inventoryTransferList);
        const tempData = respData.data.inventoryTransferList

        console.log("respData.inventoryTransferList", respData.data.inventoryTransferList)

        const ResponseOfDuplicateRemoved: any = [...new Map(tempData.map((item: any) => [item.transferringStoreNumber, item])).values()];

        const TotalStores: any = [];
        ResponseOfDuplicateRemoved.map((value: any, index: any) => {
          console.log('store1', transferReceiveData)

          if (index === 0) {
            TotalStores.push({
              label: 'Select Store',
              value: '0',
            });
          }

          console.log('logggg', ResponseOfDuplicateRemoved)

          TotalStores.push({
            label: value.transferringStoreNumber,
            value: value.transferringStoreNumber,
          });

          setStore(TotalStores)

          console.log('store2', TotalStores)
        });

        setPrintData(respData.data)
        setDropDownLoaded(true);
      }
      else {
        gridColumns.map((value: any) => {
          if (value.columnId == 'transferringStoreNumber') {
            value.sortOrder = sortDirection.ASC;
            value.IsSorted = true;
          }
          else {
            value.IsSorted = false;
          }
        })
        setTransferReceiveData([]);
        const filterDisabled = respData?.data?.inventoryTransferList
        if(filterDisabled?.length == 0 ){
          setDisableButtons(true);
        }
        // seterrMsg({...errMsg,noRecord:true});
      }
    }


  }

  const GridValues = (value: any) => {
    setTransferReceiveData(value)
  }
  const GridColumns = (value: any) => {
    setGridColumns1(value)
  }

  const Checked = (e: any, index: any, value: any, checkedArray: []) => {

    console.log('main01', value, checkedArray)
    const isChecked = checkedArray.filter((el: any) =>

      el === true
    )
    console.log('main002', isChecked)

    if (isChecked.length > 0) {
      setDisableButtons(false);
    }

    // if (isChecked.length > 1) {
    //   setDisableButtons({...disableButtons,reject:true,accept:false});
    // }

    if (value.storeType === "ANOW") {
      if (externalInventoryNumbers.size == 0) {
        const arr = new Map<string, string>();
        arr.set(value.inventoryNumber, value.transferringStoreNumber);
        setExternalInventoryNumbers(arr);
      } else {

        const htStore: any = externalInventoryNumbers.get(value.inventoryNumber);
        if (htStore) {
          externalInventoryNumbers.delete(value.inventoryNumber);
        } else {
          externalInventoryNumbers.set(value.inventoryNumber, value.transferringStoreNumber);
        }
      }
    } else {

      if (inventoryNumbers.length == 0) {
        const arr: any = [value.inventoryNumber]
        setInventoryNumbers(arr)
      } else {
        const index: any = inventoryNumbers.indexOf(value.inventoryNumber)
        if (index != -1) {
          inventoryNumbers.splice(index, 1)
        } else {
          inventoryNumbers.push(value.inventoryNumber)
        }
      }
    }

    console.log('inventoryNumbers', inventoryNumbers)
    if (isChecked.length == 0) {
      setDisableButtons(true);
    }
  }


  const CheckedAll = (e: any, value: any, checked: boolean) => {
    console.log(checked, 'checked0123all')

    if (!checked) {
      setDisableButtons(false);

      console.log('main02', value)
      const invNoArray = value.map((v: any) => {
        if (v.storeType !== "ANOW")
          return v.inventoryNumber
      }).filter((v: any) => v);
      console.log('invNoArray', invNoArray)
      setInventoryNumbers(invNoArray)

      const extInvNum = value.map((v: any) => {
        if (v.storeType === "ANOW")
          return [v.inventoryNumber, v.transferringStoreNumber]
      }).filter((v: any) => v);
      const out = new Map<string, string>(extInvNum);

      setExternalInventoryNumbers(out);

      console.log('checked0000', checked)
    }
    else {
      setDisableButtons(true);
      setInventoryNumbers([]);

    }
  }


  const rejectDropdown = async () => {

    setrejectLoader(true);
    const COresponse: any = await getInventoryStatusReasons("chargeoff");
    setrejectLoader(false);
    const rejectReasonsResponse = COresponse.data.rejectOptions;
    console.log('rejectReasonsResponse', rejectReasonsResponse);
    const rejectResponse = [
      { referenceCode: 'Select', description: 'Select' },
      ...rejectReasonsResponse,
    ];
    console.log('rejectResponse', rejectResponse);
    setrejectReasons(rejectResponse);


  }

  const acceptBase = async () => {
    console.log("eeww", inventoryNumbers, currentStoreNumber);
    const request = {
      currentStoreNumber: currentStoreNumber,
      inventoryNumbers: inventoryNumbers,
      isRejected: false,
      reason: reasonValue !== undefined ? reasonValue : ''
    }
    const respData: any = await updateTransferReceiveDetails(request);
    return respData;

  }

  const processExternal = async ( accept : boolean ) => {
    let out = {success: 0, failed: 0, continue: true};
    for (const [key, value] of externalInventoryNumbers ) {
      const request: HtAcceptRejectReq = {
        toStoreNum: currentStoreNumber ? currentStoreNumber : 'unknown',
        inventoryNumber: key,
        acceptIt: accept,
        htFromStoreNum: value
      };
      const respData: any = await updateExternalTransfers(request);
      if ( respData?.status == 200 ) {
        out.success = out.success + 1;  
      } else {
        out.failed = out.failed + 1;
      }
    }

    if (out.failed > 0 && out.success == 0) {
      out.continue = false;
    }
    return out;
  }

  const onAccept = async () => {
    acceptTransferBtnDisable = true;
    setAcceptTransferBtnDisable(acceptTransferBtnDisable);
    setEnablePopup(false);
    setmasterLoader(true);
    let respData: any;
    if ( inventoryNumbers?.length > 0 ) {
      respData = await acceptBase();
    } else {
      respData = {status: 200};    
    }
    let respExternal: any;
    if ( externalInventoryNumbers?.size  > 0 ) {
      respExternal = await processExternal(true);
    } else {
      respExternal = {continue: true};    
    }

    setmasterLoader(false);
    acceptTransferBtnDisable = false;
    setAcceptTransferBtnDisable(acceptTransferBtnDisable);

    console.log("e1", respData);

    // if (validateObject(respData) && validateObject(respData.status) && respData.status == 200) {
    //   console.log('Second-Accepte1');
    //   if (validateObject(respData.data.statusMessage) && respData.data.statusMessage == "Transfer Accept successfull") {
    //     console.log('Second-Accept');
        
    //     setPopupType("Second-Accept")
    //   }
    //   else {
    //     setPopupType("acceptFailed")
    //   }
    // }

    if(respData?.status ==200 && respExternal.continue ){
      console.log('Second-Accept');    
      setEnablePopup(true);
      setPopupType("Second-Accept")
    }
    else {
      setPopupType("acceptFailed")
      setEnablePopup(true);
      
    }

  }

  const handleOnSelect = (e: any) => {

    const value = e.target.value;
    const filtered = gridTemp.filter((v: any) => v.transferringStoreNumber == value)
    console.log('tempData', transferReceiveData)
    gridColumns.map((value: any) => {
      if (value.columnId == 'transferringStoreNumber') {
        value.sortOrder = sortDirection.ASC;
        value.IsSorted = true;
      }
      else {
        value.IsSorted = false;
      }
    })
    setTransferReceiveData(filtered);
    if (value == 0) {
      gridColumns.map((value: any) => {
        if (value.columnId == 'transferringStoreNumber') {
          value.sortOrder = sortDirection.ASC;
          value.IsSorted = true;
        }
        else {
          value.IsSorted = false;
        }
      })
      setTransferReceiveData(gridTemp);
    }
  }

  const rejectBase = async () => {
    console.log("eewww", inventoryNumbers);

    const request = {
      currentStoreNumber: currentStoreNumber,
      inventoryNumbers: inventoryNumbers,
      isRejected: true,
      reason: reasonValue
    }
    const respData: any = await updateTransferReceiveDetails(request);
    return respData;

  }
  const onClickReject = async () => {

    
    setloader({ ...loader, btnLoader: true });
    
    let respData: any;
    if ( inventoryNumbers?.length > 0 ) {
      respData = await rejectBase();
    } else {
      respData = {status: 200, data : { statusMessage:"Transfer Reject successfull"}};
    }
    
    let respExternal: any;
    if ( externalInventoryNumbers?.size  > 0 ) {
      respExternal = await processExternal(false);
    } else {
      respExternal = {continue: true};    
    }

    setloader({ ...loader, btnLoader: false });

    console.log("eyyy", respData);

    if (validateObject(respData) && validateObject(respData.status) && respData.status == 200) {
      if (validateObject(respData.data.statusMessage) && respData.data.statusMessage == "Transfer Reject successfull" && respExternal.continue) {
        setPopupType("Second-Reject")
      }
      else {
        setPopupType("rejectFailed")
      }
    }
    else {
      setPopupType("rejectFailed")
    }
  }

  const onPrint = async () => {

    // await onAccept()

    const data: any = {
      inventoryTransferList: []
    }
    console.log('noooos', inventoryNumbers, transferReceiveData, printdata)
    inventoryNumbers.forEach((v: any) => {
      const array1 = printdata.inventoryTransferList.filter((val: any) =>
        v == val.inventoryNumber
      )
      data.inventoryTransferList.push(...array1)

    })

    for (let i = 0; i < data.inventoryTransferList.length; i++) {
      data.inventoryTransferList[i] = { ...data.inventoryTransferList[i], ...printdata.currentStoreDetails }
    }

    console.log('array0l', data)
    setDataToPrint(data)
    transferReceiveGridDetailsApiCall();
  }

  const inventoryRejectReason = () => {
    if (validateObject(rejectReasons)) {
      return rejectReasons.map((entry: any) => {
        return { label: entry.description, value: entry.referenceCode };
      });
    } else {
      return [{ label: "Select", value: "Select" }];
    }
  };
  const secondLvlAuthRes = (e: any) => {
    if (e === true) {
      setPopupType('First-Reject');
      setEnablePopup(true);
    }
  }
  const getCoWorkerRole = async () => {
    try {
      const currentUserRes: any = await getCurrentUser();
      console.log('currentUserRes', currentUserRes);
      if (currentUserRes.status === 200) {
        const EmployeeId = currentUserRes.data.employeeId;
        console.log('EmployeeId', EmployeeId);
        const payload = {
          coworkerId: EmployeeId,
          storesRequired: true
        }
        console.log('menuandstores-payload', payload);
        const coWorkerRoleObj: any = await getCoworkerDetails(payload);
        if (
          validateObject(coWorkerRoleObj) && coWorkerRoleObj.status == 200 &&
          validateObject(coWorkerRoleObj.data.coworkerProfile) &&
          validateObject(coWorkerRoleObj.data.coworkerProfile.role)
        ) {
          setcurrentCWRole(coWorkerRoleObj.data.coworkerProfile.role);
        }
        console.log('coWorkerRoleObj', coWorkerRoleObj);
      }
    }
    catch (e: any) {
      console.log('Error in co worker role', e);
    }
  };
  const PopUps = (value: string) => {
    if (value == 'First-Reject') {
      return (
        <Grid >
          <Grid className={`${classes.modalBody} ${classes.mb5} ${classes.mtm28}`}>
            <Grid className={`${classes.racCol12} ${classes.px0}`}>
              <Typography className={`${classes.formLabel} ${classes.fontBold}`}>Reject Reason</Typography>
              <RACSelect
                options={inventoryRejectReason()}
                name="ReasonDD"
                defaultValue={
                  reasonValue != undefined &&
                    reasonValue != null
                    &&
                    reasonValue != ''
                    ? reasonValue
                    : 'Select'
                }
                loading={rejectReasons.length == 0 && !dropDownLoaded ? true : false}
                onChange={(e: any) => {
                  e.target.name = `Reasons`;
                  e.target.value == 'Select'?
                  setReasonValue('Select'):
                  setReasonValue(e.target.value);
                  
                }}
                {...(rejectReasons.length == 0 &&
                  dropDownLoaded && {
                  errorMessage: API_ERROR_MESSAGE,
                })}
              />
            </Grid>
          </Grid>
          <Grid className={`${classes.racCol12} ${classes.px3} ${classes.textRight}`} style={{ marginTop: '-30px' }}>
            <Grid>
              <RACButton
                variant="contained" color="primary" className={`${transferStyles.bottomButtonRight}`}
                onClick={async () =>  onClickReject()}
                disabled ={reasonValue =='Select' ?true:false}
                loading={loader.btnLoader}
              >
                Save
              </RACButton>
            </Grid>

            <Grid>
              <RACButton
                variant="outlined" color="primary" className={`${transferStyles.bottomButtonRight} ${classes.textGrey}`}
                onClick={() => { setEnablePopup(false), setsecondLvlAuth(false) }}
              >
                Cancel
              </RACButton>
            </Grid>
          </Grid>
        </Grid >
      )

    }
    else if (value == 'Second-Reject') {
      return (
        <Grid className={` ${transferStyles.popupStyle} ${classes.textCenter} `}
        >
          <Grid
          >
            <TickIcon
            ></TickIcon>
          </Grid>
          <Grid
          >
            <Typography
              className={`${transferStyles.popupTitle}  `}
            >
              Reject Successfully Performed.
            </Typography>
          </Grid>
          <Grid>
            <Grid>
              <RACButton
                variant="contained" color="primary"
                onClick={() => { setEnablePopup(false); transferReceiveGridDetailsApiCall() }}
              >
                OK
              </RACButton>
            </Grid>
          </Grid>
        </Grid >

      )
    }
    else if (value == 'First-Accept') {
      return (
        <>
          <Grid className={`${classes.modalBody} ${classes.mb3} ${classes.mtm28}`}>
            <Grid className={`${classes.racCol12} ${classes.px0} ${classes.textCenter}`}>
              <Typography className={` ${transferStyles.popupTitle} ${transferStyles.w55} ${classes.textCenter}`} style={{ marginLeft: '56px' }}>
                Accept transferred items into inventory?
              </Typography>
            </Grid>
          </Grid>
          <Grid className={`${classes.racCol12} ${classes.px3} ${classes.textCenter} ${classes.mb4}`} style={{ marginTop: '-30px' }}>

            <RACButton
              size="small"
              variant="outlined"
              color="primary"
              className={`${classes.pt9} ${classes.pb9} ${classes.px8}`}
              onClick={() => setEnablePopup(false)}
            >
              No
            </RACButton>
            <RACButton
              size="small"
              id='Accept'
              variant="contained"
              color="primary"
              className={`${classes.pt9} ${classes.pb9} ${classes.px8} ${classes.ml6}`}
              onClick={() => onAccept()}
              loading={loader.btnLoader}
              disabled={acceptTransferBtnDisable}
            >
              Yes
            </RACButton>

          </Grid>
        </>
      )
    }
    else if (value == 'Second-Accept') {
      return (
        <Grid className={` ${classes.title} ${classes.w100} ${classes.textCenter} `}>
          <Grid>
            <TickIcon></TickIcon>
          </Grid>
          <Grid>
            <Typography className={`${classes.title} `}
            >
              Accept Successfully Performed.
            </Typography>
          </Grid>
          <Grid>
            <Grid>
              <RACButton
                variant="contained" color="primary"
                onClick={() => { setEnablePopup(false); onPrint() }}
              >
                OK
              </RACButton>

            </Grid>
          </Grid>
        </Grid>
      )

    } else if (value == 'rejectFailed' || value == 'acceptFailed') {
      return (
        <Grid
          className={` ${classes.title}  ${classes.textCenter} `}
        >
          <Grid>
            <WrongIcon
            ></WrongIcon>
          </Grid>
          <Grid>
            <Typography className={`  ${classes.title}`}
            >
              {
                value == 'rejectFailed' ? "Unable to reject at this moment" : "Unable to accept at this moment"
              }
            </Typography>
          </Grid>
          <Grid>
            <Grid
            >
              <RACButton
                variant="contained" color="primary"
                onClick={() => { setEnablePopup(false), setsecondLvlAuth(false) }}
              >
                OK
              </RACButton>

            </Grid>
          </Grid>
        </Grid >
      )
    }
    else if (value == 'multipleReject') {
      return (
        <Grid
          className={` ${classes.title}  ${classes.textCenter} `}
        >
          <Grid>
            <WrongIcon
            ></WrongIcon>
          </Grid>
          <Grid>
            <Typography className={`  ${classes.title}`}
            >

              Please select a single item to Reject

            </Typography>
          </Grid>
          <Grid>
            <Grid
            >
              <RACButton
                variant="contained" color="primary"
                onClick={() => { setEnablePopup(false), setsecondLvlAuth(false) }}
              >
                OK
              </RACButton>

            </Grid>
          </Grid>
        </Grid >
      )
    }
  }

  const rejectbtn = async () => {
    
      if ( (inventoryNumbers.length + externalInventoryNumbers.size ) != 1) {
        setPopupType('multipleReject');
        setEnablePopup(true);
      }
      else {
        await rejectDropdown();
        setsecondLvlAuth(true);
      }

    
  }
  // const rejectbtn = () => {
  //   if (disableButtons.reject !== true) {
  //       setsecondLvlAuth(true); 
  //   }
  // }

  return (
    <React.Fragment>
                  {masterLoader ? (
                <Grid className={classes.masterLoader}>
                    <Grid className={classes.Loader}>
                        <CircularProgress />
                    </Grid>
                </Grid>
            ) : null}
      <Grid className={classes.mbFooterSpacing}>

        <Grid className={classes.w100}>
          <List className={transferStyles.breadcrumb}>
            <ListItem className={transferStyles.breadcrumbItem}>
            <Typography  className={transferStyles.breadcrumblinkfont}  onClick={() => history.push('/dashboard')} style={{cursor:'pointer',fontSize:'12px!important'}}>
                          Dashboard
                      </Typography>
            </ListItem>
            <ListItem className={`${transferStyles.breadcrumbItem} ${transferStyles.breadcrumbArrow} ${transferStyles.breadcrumbItemActive}`}>
              Inventory Transfer Receive
            </ListItem>
          </List>
        </Grid>

        <Grid item md={12} className={classes.componentSpaceBetween}>
          <Grid className=
            {`${classes.mb3} ${classes.mt1} ${classes.mx3}`}
          // {classes.m2}
          >
            <Typography variant="h4">Accept Inventory Transfer</Typography>
          </Grid>
          <Grid
            className={`${classes.mb3} ${classes.mt1} ${classes.mx3} ${classes.componentLeft}`}
          >
            <Typography variant="caption" className={`${classes.mr3} ${classes.fs7} ${classes.fontBold}`}>Store</Typography>
            <Grid style={{ width: '120px' }}>
              <RACSelect
                options={store}
                name="FromStore"
                classes={{ nativeSelect: classes.selectBox }}
                defaultValue={
                  storeValue != undefined &&
                    storeValue != null &&
                    storeValue != ''
                    ? storeValue
                    : '0'
                }
                loading={store.length == 0 && !dropDownLoaded ? true : false}
                onChange={(e: any) => {
                  e.target.name = `FromStore`;
                  handleOnSelect(e);
                  setStoreValue(e.target.value);
                  setInventoryNumbers([]);
                  setDisableButtons(true);
                }}
                {...(store.length == 0 &&
                  dropDownLoaded && {
                  errorMessage: API_ERROR_MESSAGE,
                })}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item md={12}>
          {loader.pageLoader === true ? (
            <Card className={`${classes.mx3} ${classes.racCard}`} style={{ minHeight: "520px" }}>
              <CardContent>
                <Grid>
                  <Grid className={classes.racLoaderPage}>
                    <CircularProgress style={{ marginTop: '0px' }} />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>) :
            errMsg.wentWrong === true ? (
              <Card className={`${classes.mx3} ${classes.racCard}`} style={{ minHeight: "520px" }}>
                <CardContent>
                  <Grid className={`${classes.textCenter} ${classes.mt5}`} style={{ padding: "10%" }}>
                    <WrongIcon />
                    <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                      Something Went Wrong
                    </Typography>
                  </Grid>
                </CardContent>
              </Card>) :
              errMsg.noRecord === true ? (
                <Card className={`${classes.mx3} ${classes.racCard}`} style={{ minHeight: "520px" }}>
                  <CardContent>
                    <Grid className={`${classes.textCenter} ${classes.mt5}`} style={{ padding: "10%" }}>
                      <NoRecord />
                      <Typography className={`${classes.subTitle} ${classes.mb3}`}>
                        No Records Found
                      </Typography>
                    </Grid>
                  </CardContent>
                </Card>
              ) :
                (
                  <Card className={`${classes.mx3} ${classes.racCard}`}>
                    <CardContent>
                      <DynamicGridComponent
                        gridtype="withCheckBox"
                        id="TransferReceive"
                        // maxHeight="390"
                        sortRefresh={setInventoryNumbers}
                        items={transferReceiveData}
                        columns={gridColumns}
                        orderBy={orderBy}
                        order={order}
                        sorting={true}
                        gridColumn={gridColumns}
                        gridValues={transferReceiveData}
                        setGridValues={(e) => GridValues(e)}
                        setGridColumns={(e) => GridColumns(e)}
                        handleCheckedAll={(e, dataValues, checked) => CheckedAll(e, dataValues, checked)}
                        handleChecked={(e, index, value, array) => Checked(e, index, value, array)}
                        throwStyle={classes}
                        setOrder={(e) => setOrder(e)}
                        setOrderBy={(e) => setOrderBy(e)} handleActionType={function (e: any): void {
                          throw new Error('Function not implemented.');
                        }} handleAnkerType={function (e: any, value: any): void {
                          throw new Error('Function not implemented.');
                        }}
                      ></DynamicGridComponent>
                    </CardContent>
                  </Card>
                )

          }

        </Grid>
      </Grid>

      <Grid container className={classes.fixedFooter}>
        <Grid>
          <RACButton variant="outlined" color="primary" className={` ${classes.textBlack}`} onClick={() => {
            history.push({
              pathname: `/dashboard`,
            })
          }}>Cancel</RACButton>
        </Grid>
        <Grid>
          <RACButton variant="contained" color="primary"
            className={disableButtons === true ? `${classes.mr2} ${classes.px5} ${classes.bgTransparentRed}` : `${classes.mr2} ${classes.px5} ${classes.bgRed} ${classes.onBtnHover}`}
            onClick={async () =>  rejectbtn()}
          >Reject</RACButton>
          <RACButton variant="contained" color="primary" className={`${classes.mr2} ${classes.px5}`} disabled={disableButtons} onClick={() => { setPopupType("First-Accept"); setEnablePopup(true) }} >Accept</RACButton>
        </Grid>
      </Grid>

      <div id="PrintTransferRecieve" style={{ display: "none" }}>
        <PrintTag dataRows={dataToPrint}>
        </PrintTag>
      </div >

      {
        secondLvlAuth == true ? (
          <TwoFactorComponent
            setTwoFactorCancelClick={() => setsecondLvlAuth(false)}
            setTwoFactorCompleted={secondLvlAuthRes}
            moduleName={'Reject Transferred Inventory'}
            CurrentRole={currentCWRole}
            employeeID={employeeId}
            getprofileaccessResponse={profileDetails}

          ></TwoFactorComponent>
        ) : null
      }

      <Grid>
        <RACModalCard
          className={` ${classes.saveModalWidth}`}
          isOpen={enablePopup}
          maxWidth="xs"
          closeIcon={(popupType == 'First-Reject' || popupType == 'First-Accept') ? true : false}
          onClose={() => { setEnablePopup(false); setsecondLvlAuth(false) }}
          borderRadius={`16px !important`}
          children={PopUps(popupType)}
          title=""
        />
      </Grid>
    </React.Fragment >
  );
}

